import React from "react"
import Container from "../components/container"
import Footer from "../components/footer"
import { Helmet } from "react-helmet"


export default () => (
    <Container>
        <Helmet>
            <title>Poster | An Exquisite Meal</title>
            <meta name="description" content="Download the poster." />
        </Helmet>

        <div class="sub-container-page">
            <div class="body-content">
                <a href="/"><img src="../AnExquisiteMeal_headline.png" width="600" alt="An Exquisite Meal" /></a>
                

                <img src="https://anexquisitemeal.com/AnExquisiteMeal_2000x3000_LoRes.jpg" width="500" alt="An Exquisite Meal - Poster" />

            </div>
        </div>
        <Footer>
        </Footer>
    </Container>

)

